<template>
 <div id="PageNotFound">
  <b-container>
   <b-row>
    <b-col>
     <div class="imgPageNotFound">
      <img src="../assets/PageNotFound_404-Error.svg" alt="" />
     </div>
     <div class="buttonsBackHomePage">
      <button><router-link class="link" to="/">Home Page <i class="fas fa-home"></i></router-link></button>
      <button><router-link class="link" to="/contacts">Contact Support <i class="fas fa-info-circle"></i></router-link></button>
     </div>
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {
 name: "PageNotFound",
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#PageNotFound {

 .imgPageNotFound {
   margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  img {
   width: 40%;
  }
 }
 .buttonsBackHomePage {
    margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
   border: none;
   background-color: map-get($branding ,  them-color-option-tow);
   color: blanchedalmond;
   font-size: 18px;
   font-weight: 400;
   border-radius: 3px;
   padding: 6px 92px;
   margin: 5px 0;
   .link{
    color: #FFF;
    text-decoration: none;
   }
  }
 }
}
</style>
