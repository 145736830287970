<template>
 <div>
  <Navigation/>
<PageNotFound/>
<Partners/>
<Footer/>
 </div>
</template>

<script>
import PageNotFound from "../components/PageNotFound.vue";
import Navigation from "../components/Navigation.vue";
import Partners from "../components/Partners.vue";
import Footer from "../components/Footer.vue";
 export default {
    components: { PageNotFound, Navigation, Partners, Footer }
}
</script>

<style lang="sass" scoped>

</style>